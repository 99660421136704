<template>
  <div
    class="userguanli"
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <div class="sousuo">
      <div class="div1">
        <span>菜单名称</span>
        <el-select class="select" v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="div1">
        <span>部门</span>
        <el-select class="select" v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="div1">
        <span>关键字</span>

        <el-select
          style="width: 100px"
          class="select"
          v-model="value"
          placeholder="ID"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <input class="inputs" type="text" />
      </div>
      <img class="imgs" src="../../assets/images/query.png" alt />
    </div>
    <div class="tables">
      <el-table :data="tableData" style="width: 100%">
        <!-- <el-table-column prop="id" label="ID" width="120"></el-table-column> -->

        <el-table-column
          prop="userName"
          label="用户名登录名称"
          width="160"
        ></el-table-column>
        <!-- <el-table-column prop="photoUrl" label="头像路径" width="180"></el-table-column> -->
        <el-table-column
          prop="phone"
          label="手机号"
          width="150"
        ></el-table-column>
        <el-table-column prop="name" label="姓名" width="120"></el-table-column>
        <el-table-column prop="sex" label="性别" width="100">
          <template slot-scope="scope">{{
            scope.row.sex == "1" ? "男" : "女"
          }}</template>
        </el-table-column>
        <!-- <el-table-column
          prop="entryDate"
          label="入职日期"
          width="150"
          :formatter="formatter"
        >
        </el-table-column> -->
        <!-- <el-table-column
          prop="quitDate"
          label="离职日期"
          width="150"
          :formatter="formatter"
        >
        </el-table-column> -->
        <el-table-column prop="entryDate" label="入职日期" width="150">
        </el-table-column>
        <el-table-column prop="quitDate" label="离职日期" width="150">
        </el-table-column>
        <el-table-column prop="loginDate" label="最近登录日期" width="160">
        </el-table-column>
        <el-table-column prop="workState" label="在职状态" width="100">
        </el-table-column>
        <el-table-column prop="useState" label="是否可登录" width="120">
        </el-table-column>
        <!-- <el-table-column prop="delFlag" label="删除标识" width="100">
          <template slot-scope="scope">{{
            scope.row.delFlag ? "有效" : "删除"
          }}</template>
        </el-table-column> -->
        <!-- <el-table-column prop="createDate" label="创建日期" width="120">
          <template slot-scope="scope">
            {{scope.row.createDate | dateFormatYYmmdd}}
          </template>
        </el-table-column>
        <el-table-column prop="updateDate" label="修改日期" width="120">
           <template slot-scope="scope">
            {{scope.row.updateDate | dateFormatYYmmdd}}
          </template>
        </el-table-column> -->
        <!-- <el-table-column
          prop="remark"
          label="备注"
          width="80"
        ></el-table-column> -->

        <el-table-column prop="caozuo" label="操作" width="230">
          <template slot-scope="scope">
            <img
              class="buts"
              src="../../assets/images/edits.png"
              @click="changeUser(scope.$index, scope.row)"
              alt
            />
            <img
              class="buts"
              src="../../assets/images/del.png"
              @click="handleEdit(scope.$index, scope.row)"
              alt
            />
            <el-button
              class="details"
              size="mini"
              @click="details(scope.$index, scope.row)"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="totals">
      <!-- 左 -->
      <div class="left">
        <p>
          总用户数
          <span class="spanto">{{ totalCount }}</span
          >人
        </p>
      </div>
      <!-- 右 -->
      <div class="total">
        <!-- 分页 -->
        <div class="pages">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage1"
            :page-size="this.pageSize"
            layout="total, prev, pager, next"
            :total="this.totalCount"
          >
            <img class="lefts" src="../../assets/images/leftpage.png" alt />
            <img class="rights" src="../../assets/images/rightpage.png" alt />
          </el-pagination>
        </div>
        <!-- 按钮 -->
        <div class="buttons">
          <el-button type="text" @click="add">添加用户</el-button>
        </div>
      </div>
    </div>

    <!-- 增加弹框-->
    <div class="accountadd">
      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        width="50%"
        :before-close="clearUserForm"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="130px"
          class="demo-ruleForm"
          :inline="true"
        >
          <el-form-item label="用户名登录名称" prop="name" v-if="istext">
            <el-input
              v-model="ruleForm.userName"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="用户密码" prop="name" v-if="istext">
            <el-input
              v-model="ruleForm.userPass"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="name">
            <el-input v-model="ruleForm.phone" style="width: 196px"></el-input>
          </el-form-item>
          <el-form-item label="名字" prop="name">
            <el-input v-model="ruleForm.name" style="width: 196px"></el-input>
          </el-form-item>
          <el-form-item label="是否可登录" prop="" v-if="isShow">
            <el-select
              v-model="ruleForm.useState"
              placeholder="请选择"
              @change="getUserValue1"
            >
              <el-option
                v-for="item in options5"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="性别" prop="">
            <el-select
              v-model="ruleForm.sex"
              placeholder="请选择"
              @change="getSexValue"
            >
              <el-option
                v-for="item in options2"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="入职日期" prop="entryDate">
            <el-col :span="23">
              <el-date-picker
                type="datetime"
                placeholder="入职日期"
                v-model="ruleForm.entryDate"
                style="width: 100%"
                format="yyyy-MM-dd HH:mm:ss"
              ></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item
            label="离职日期"
            prop="name"
            v-if="isShow"
            class="lizhi"
          >
            <el-col :span="23">
              <el-date-picker
                type="datetime"
                placeholder="离职日期"
                v-model="ruleForm.quitDate"
                style="width: 100%"
                format="yyyy-MM-dd HH:mm:ss"
              ></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item label="机构ID" prop="">
            <el-select
              v-model="ruleForm.orgId"
              placeholder="请选择"
              @change="getjigouValue"
            >
              <el-option
                v-for="item in options3"
                :key="item.code"
                :label="item.orgName"
                :value="item.id"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="角色ID" prop="">
            <el-select
              v-model="ruleForm.roleId"
              placeholder="请选择"
              @change="getUserRoleValue(ruleForm.roleId)"
            >
              <el-option
                v-for="item in options4"
                :key="item.id"
                :label="item.roleName"
                :value="item.id"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="在职状态" prop="">
            <el-select
              v-model="ruleForm.workState"
              placeholder="请选择"
              @change="getUserValue(ruleForm.workState)"
            >
              <el-option
                v-for="item in options1"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否为客服" prop="">
            <el-select
              v-model="ruleForm.cusSerSign"
              placeholder="请选择"
              @change="getUserValueCus(ruleForm.cusSerSign)"
            >
              <el-option
                v-for="item in optionsCus"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="客服类型" prop="" v-if="isCurs">
            <el-select
              v-model="ruleForm.serviceType"
              placeholder="请选择"
              @change="getserviceType(ruleForm.serviceType)"
            >
              <el-option
                v-for="item in optionsserviceType"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="坐席工号" prop="" v-if="isCurs">
            <el-input v-model="ruleForm.cno" style="width: 196px"></el-input>
          </el-form-item>
          <el-form-item label="客服岗位" prop="" v-if="isCurs">
            <el-select
              v-model="ruleForm.post"
              placeholder="请选择"
              @change="getpost(ruleForm.post)"
            >
              <el-option
                v-for="item in optionspost"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="企业编码" prop="" v-if="isCurs">
            <el-input
              v-model="ruleForm.enterpriseCode"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="客服号登录密码" prop="" v-if="isCurs">
            <el-input
              v-model="ruleForm.cnoPassword"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="绑定电话类型" prop="" v-if="isCurs">
            <el-select
              v-model="ruleForm.bindTelType"
              placeholder="请选择"
              @change="getbindTelType(ruleForm.bindTelType)"
            >
              <el-option
                v-for="item in optionsbindTelType"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="绑定电话" prop="" v-if="isCurs">
            <el-input
              v-model="ruleForm.bindTel"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="工号" prop="" v-if="isCurs">
            <el-input
              v-model="ruleForm.workNumber"
              style="width: 196px"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="clearUserForm">取 消</el-button>
          <el-button type="primary" @click="addUserList">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 修改弹框-->
    <div class="accountadd">
      <el-dialog
        :title="title"
        :visible.sync="dialogVisibleChange"
        width="50%"
        :before-close="clearUserFormChange"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="130px"
          class="demo-ruleForm"
          :inline="true"
        >
          <el-form-item label="用户名登录名称" prop="name" v-if="istext">
            <el-input
              v-model="ruleForm.userName"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="用户密码" prop="name" v-if="istext">
            <el-input
              v-model="ruleForm.userPass"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="name">
            <el-input v-model="ruleForm.phone" style="width: 196px"></el-input>
          </el-form-item>
          <el-form-item label="名字" prop="name">
            <el-input v-model="ruleForm.name" style="width: 196px"></el-input>
          </el-form-item>
          <el-form-item label="是否可登录" prop="" v-if="isShow">
            <el-select
              v-model="ruleForm.useState"
              placeholder="请选择"
              @change="getUserValue1(ruleForm.useState)"
            >
              <el-option
                v-for="item in options5"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="性别" prop="">
            <el-select
              v-model="ruleForm.sex"
              placeholder="请选择"
              @change="getSexValue"
            >
              <el-option
                v-for="item in options2"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="入职日期" prop="entryDate">
            <el-col :span="23">
              <el-date-picker
                type="datetime"
                placeholder="入职日期"
                v-model="ruleForm.entryDate"
                style="width: 100%"
                format="yyyy-MM-dd HH:mm:ss"
              ></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item
            label="离职日期"
            prop="name"
            v-if="isShow"
            class="lizhi"
          >
            <el-col :span="23">
              <el-date-picker
                type="datetime"
                placeholder="离职日期"
                v-model="ruleForm.quitDate"
                style="width: 100%"
                format="yyyy-MM-dd HH:mm:ss"
              ></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item label="机构ID" prop="">
            <el-select
              v-model="ruleForm.orgId"
              placeholder="请选择"
              @change="getjigouValue"
            >
              <el-option
                v-for="item in options3"
                :key="item.code"
                :label="item.orgName"
                :value="item.id"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="角色ID" prop="">
            <el-select
              v-model="roleValue"
              placeholder="请选择"
              @change="getUserRoleValue"
            >
              <el-option
                v-for="item in options4"
                :key="item.id"
                :label="item.roleName"
                :value="item.id"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="在职状态" prop="">
            <el-select
              v-model="ruleForm.workState"
              placeholder="请选择"
              @change="getUserValue(ruleForm.workState)"
            >
              <el-option
                v-for="item in options1"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否为客服" prop="">
            <el-select
              v-model="ruleForm.cusSerSign"
              placeholder="请选择"
              @change="getUserValueCus(ruleForm.cusSerSign)"
            >
              <el-option
                v-for="item in optionsCus"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="客服类型" prop="" v-if="isCurs">
            <el-select
              v-model="ruleForm.serviceType"
              placeholder="请选择"
              @change="getserviceType(ruleForm.serviceType)"
            >
              <el-option
                v-for="item in optionsserviceType"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="坐席工号" prop="" v-if="isCurs">
            <el-input v-model="ruleForm.cno" style="width: 196px"></el-input>
          </el-form-item>
          <el-form-item label="客服岗位" prop="" v-if="isCurs">
            <el-select
              v-model="ruleForm.post"
              placeholder="请选择"
              @change="getpost(ruleForm.post)"
            >
              <el-option
                v-for="item in optionspost"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="企业编码" prop="" v-if="isCurs">
            <el-input
              v-model="ruleForm.enterpriseCode"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="客服号登录密码" prop="" v-if="isCurs">
            <el-input
              v-model="ruleForm.cnoPassword"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="绑定电话类型" prop="" v-if="isCurs">
            <el-select
              v-model="ruleForm.bindTelType"
              placeholder="请选择"
              @change="getbindTelType(ruleForm.bindTelType)"
            >
              <el-option
                v-for="item in optionsbindTelType"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="绑定电话" prop="" v-if="isCurs">
            <el-input
              v-model="ruleForm.bindTel"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="工号" prop="" v-if="isCurs">
            <el-input
              v-model="ruleForm.workNumber"
              style="width: 196px"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="clearUserFormChange">取 消</el-button>
          <el-button type="primary" @click="changeUserLs">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <!-- 点击详情的弹框 -->
    <div class="account">
      <el-dialog
        title="用户详情"
        :visible.sync="dialogVisible1"
        width="50%"
        :before-close="detailsX"
      >
        <el-form
          :model="ruleForm1"
          :rules="rules"
          ref="ruleForm"
          label-width="130px"
          class="demo-ruleForm"
          :inline="true"
        >
          <!-- <el-form-item label="id" prop="name" v-if="isShow">
            <el-input v-model="ruleForm1.id"></el-input>
          </el-form-item> -->
          <el-form-item label="用户名 :" prop="name" :disabled="true">
            <el-input v-model="ruleForm1.userName" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="性别 :" prop="name">
            <el-input v-model="ruleForm1.sex" :disabled="true"></el-input>
          </el-form-item>
          <!-- <el-form-item label="创建人的ID" prop="name" v-if="isShow">
            <el-input v-model="ruleForm1.createId"></el-input>
          </el-form-item>
          <el-form-item label="修改人的ID" prop="name" v-if="isShow">
            <el-input v-model="ruleForm1.updateId"></el-input>
          </el-form-item> -->
          <el-form-item label="用户头像路径 :" prop="name">
            <el-input v-model="ruleForm1.photoUrl" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="用户手机号 :" prop="name">
            <el-input v-model="ruleForm1.phone" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="最近登录日期 :" prop="name">
            <el-input v-model="ruleForm1.loginDate" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="所属机构名称 :" prop="createDate">
            <el-input v-model="ruleForm1.orgName" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="是否删除标志 :" prop="name">
            <el-input v-model="ruleForm1.delFlag" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="创建人名称 :" prop="name">
            <el-input
              v-model="ruleForm1.createName"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="修改人名称 :" prop="name">
            <el-input
              v-model="ruleForm1.updateName"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="可登录状态 :" prop="name">
            <el-input v-model="ruleForm1.useState" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="用户昵称 :" prop="name">
            <el-input v-model="ruleForm1.name" :disabled="true"></el-input>
          </el-form-item>
          <!-- <el-form-item label="父级ID" prop="name" v-if="isShow">
            <el-input v-model="ruleForm1.parentId"></el-input>
          </el-form-item> -->
          <el-form-item label="入职日期 :" prop="name">
            <el-input v-model="ruleForm1.entryDate" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="离职日期 :" prop="name">
            <el-input v-model="ruleForm1.quitDate" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="在职状态 :" prop="name">
            <el-input v-model="ruleForm1.workState" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="角色名称 :" prop="name">
            <el-input v-model="ruleForm1.roleName" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="创建日期 :" prop="name">
            <el-input
              v-model="ruleForm1.createDate"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="修改日期 :" prop="name">
            <el-input
              v-model="ruleForm1.updateDate"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="是否为客服" prop="">
            <el-input
              v-model="ruleForm1.cusSerSign"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="客服类型" prop="" v-if="isCurs">
            <el-input
              v-model="ruleForm1.serviceType"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="坐席工号" prop="" v-if="isCurs">
            <el-input
              v-model="ruleForm1.cno"
              style="width: 230px"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="客服岗位" prop="" v-if="isCurs">
            <el-input v-model="ruleForm1.post" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="企业编码" prop="" v-if="isCurs">
            <el-input
              v-model="ruleForm1.enterpriseCode"
              style="width: 230px"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="客服号登录密码" prop="" v-if="isCurs">
            <el-input
              v-model="ruleForm1.cnoPassword"
              style="width: 230px"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="绑定电话类型" prop="" v-if="isCurs">
            <el-input
              v-model="ruleForm1.bindTelType"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="绑定电话" prop="" v-if="isCurs">
            <el-input
              v-model="ruleForm1.bindTel"
              style="width: 230px"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="工号" prop="" v-if="isCurs">
            <el-input
              v-model="ruleForm1.workNumber"
              style="width: 230px"
              :disabled="true"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="detailsX">关闭</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 二次弹框 -->
    <el-dialog title="提示" :visible.sync="dialogVisibleTwo" width="30%">
      <span>确认删除此信息吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleTwo = false">取 消</el-button>
        <el-button type="primary" @click="deleteTwo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import rolemanage from "../system_maintain/rolemanage";
// import addaccount from "../system_maintain/addaccount";
import { api } from "../../api1/config";
import moment from "moment";
export default {
  data() {
    return {
      clientHeight: document.body.clientHeight,
      twoID: "",
      isCurs: false,
      // 弹框
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisibleChange: false,
      dialogVisibleTwo: false,
      istext: false,
      isShow: true,
      title: "添加用户",
      ruleForm: {
        userName: "",
        userPass: "",
        photoUrl: "",
        phone: "",
        orgId: "",
        roleId: "",
        name: "",
        sex: "",
        entryDate: "",
        workState: "",
        cusSerSign: "",
        serviceType: "",
        cno: "",
        enterpriseCode: "",
        bindTelType: "",
        bindTel: "",
        workNumber: "",
        cnoPassword: "",
        post: "",
      },
      ruleForm1: {
        userName: "",
        photoUrl: "",
        phone: "",
        orgName: "",
        name: "",
        sex: "",
        entryDate: "",
        quitDate: "",
        loginDate: "",
        workState: "",
        useState: "",
        roleName: "",
        createName: "",
        updateName: "",
        delFlag: "",
        createDate: "",
        updateDate: "",
      },
      currentPage1: 1,
      pageSize: 10,
      totalCount: 1000,
      pageCount: "", //网络请求的页码
      options: [
        {
          value: "选项1",

          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value1: "",
      value2: "",
      value3: "",
      value4: "",
      value5: "",
      value6: "",
      options1: [],
      options2: [],
      options3: [],
      options4: [],
      options5: [],
      optionsCus: [],
      optionsserviceType: [],
      optionspost: [],
      optionsbindTelType: [],
      value: "",
      orgIds: null,
      //   table
      tableData: [],
      rules: {
        userPass: [
          { required: true, message: "请输入ID名称", trigger: "blur" },
          {
            min: 6,
            max: 12,
            message: "长度在 6 到 12 个字符",
            trigger: "blur",
          },
        ],
      },
      roIdAA: null,
      userId: "",
      flag: false,
      flagWS: false,
      flagsex: false,
      flagORG: false,
      flagROLE: false,
      flagcusSER: false,
      flagseverType: false,
      falgPOST: false,
      flagbindT: false,
      WS: "",
      US: "",
      XB: "",
      ORG: "",
      ROLE: "",
      CS: "",
      ST: "",
      PT: "",
      BT: "",
      roleValue: "",
    };
  },
  // components: {
  //   rolemanage,
  //   addaccount
  // },
  created() {
    this.menulist(1);
    this.workIng(); //查询大类下的小类
    this.sexXia(); //性别的下拉框
    this.searchAllJigou(); //查询所有机构
    this.serviceTs();
    this.cnoWei();
    this.bindTelTypes();
    this.dengLu();
    this.zaizhi();
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    //用户列表
    menulist(page) {
      api.getUserList({ pageCount: page }).then((res) => {
        console.log("用户列表:", res.data);
        if (res.data.code === 200) {
          //对于离职日期的处理
          for (let i = 0; i < res.data.result.data.length; i++) {
            if (res.data.result.data[i].quitDate == null) {
              res.data.result.data[i].quitDate = "";
            } else {
              let a = res.data.result.data[i].quitDate;
              let dd = new Date(a);
              let y = dd.getFullYear();
              let m = dd.getMonth() + 1;
              m = m < 10 ? "0" + m : m;
              let d = dd.getDate();
              d = d < 10 ? "0" + d : d;
              //let h = dd.getHours();
              let minute = dd.getMinutes();
              minute = minute < 10 ? "0" + minute : minute;
              let second = dd.getSeconds();
              second = second < 10 ? "0" + second : second;
              res.data.result.data[i].quitDate = y + "-" + m + "-" + d;
              // res.data.result.data[i].quitDate =
              //   y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
            }
          }
          //对于入职日期的处理
          for (let j = 0; j < res.data.result.data.length; j++) {
            if (res.data.result.data[j].entryDate == null) {
              res.data.result.data[j].entryDate = "";
            } else {
              let a1 = res.data.result.data[j].entryDate;
              let dd1 = new Date(a1);
              let y1 = dd1.getFullYear();
              let m1 = dd1.getMonth() + 1;
              m1 = m1 < 10 ? "0" + m1 : m1;
              let d1 = dd1.getDate();
              d1 = d1 < 10 ? "0" + d1 : d1;
              //let h1 = dd1.getHours();
              let minute1 = dd1.getMinutes();
              minute1 = minute1 < 10 ? "0" + minute1 : minute1;
              let second1 = dd1.getSeconds();
              second1 = second1 < 10 ? "0" + second1 : second1;
              res.data.result.data[j].entryDate = y1 + "-" + m1 + "-" + d1;
              // res.data.result.data[j].entryDate=
              //   y1 + "-" + m1 + "-" + d1 + " " + h1 + ":" + minute1 + ":" + second1;
            }
          }
          //对于最近登录日期的处理
          for (let k = 0; k < res.data.result.data.length; k++) {
            if (res.data.result.data[k].loginDate == null) {
              res.data.result.data[k].loginDate = "";
            } else {
              let a2 = res.data.result.data[k].loginDate;
              let dd2 = new Date(a2);
              let y2 = dd2.getFullYear();
              let m2 = dd2.getMonth() + 1;
              m2 = m2 < 10 ? "0" + m2 : m2;
              let d2 = dd2.getDate();
              d2 = d2 < 10 ? "0" + d2 : d2;
              //let h2 = dd2.getHours();
              let minute2 = dd2.getMinutes();
              minute2 = minute2 < 10 ? "0" + minute2 : minute2;
              let second2 = dd2.getSeconds();
              second2 = second2 < 10 ? "0" + second2 : second2;
              res.data.result.data[k].loginDate = y2 + "-" + m2 + "-" + d2;
              // res.data.result.data[k].loginDate=
              //   y2 + "-" + m2 + "-" + d2 + " " + h2+ ":" + minute2 + ":" + second2;
            }
          }
          this.tableData = res.data.result.data;
          this.totalCount = res.data.result.page.totalCount;
          this.pageSize = res.data.result.page.pageSize;
        } else if (res.data.msg == "token不正确") {
          this.$router.push("/");
          this.$message.error("token不正确");
        }
      });
    },
    //添加用户
    add() {
      this.dialogVisible = true;
      this.title = "添加用户";
      this.isShow = false;
      this.istext = true;
    },
    //添加用户
    addUserList() {
      this.dialogVisible = false;
      if (this.title == "添加用户") {
        this.title == "添加用户";
        //对于入职日期的处理
        let chinaStanard = this.ruleForm.entryDate;
        let dat = new Date(chinaStanard);
        let y = dat.getFullYear();
        let m = dat.getMonth() + 1;
        m = m < 10 ? "0" + m : m;
        let d = dat.getDate();
        d = d < 10 ? "0" + d : d;
        let h = dat.getHours();
        let minute = dat.getMinutes();
        minute = minute < 10 ? "0" + minute : minute;
        let second = dat.getSeconds();
        second = second < 10 ? "0" + second : second;
        this.ruleForm.entryDate =
          y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
        //对于离职日期的处理
        let chinaStanard1 = this.ruleForm.quitDate;
        let dat1 = new Date(chinaStanard1);
        let y1 = dat1.getFullYear();
        let m1 = dat1.getMonth() + 1;
        m1 = m1 < 10 ? "0" + m1 : m1;
        let d1 = dat1.getDate();
        d1 = d1 < 10 ? "0" + d1 : d1;
        let h1 = dat1.getHours();
        let minute1 = dat1.getMinutes();
        minute1 = minute1 < 10 ? "0" + minute1 : minute1;
        let second1 = dat1.getSeconds();
        second1 = second1 < 10 ? "0" + second1 : second1;
        this.ruleForm.quitDate =
          y1 + "-" + m1 + "-" + d1 + " " + h1 + ":" + minute1 + ":" + second1;
        let {
          userName,
          userPass,
          photoUrl,
          phone,
          orgId,
          roleId,
          name,
          sex,
          entryDate,
          workState,
          date,
          cusSerSign,
          serviceType,
          cno,
          enterpriseCode,
          bindTelType,
          bindTel,
          workNumber,
          cnoPassword,
          post,
        } = this.ruleForm;
        //判断电话号码
        if (!/^1[0123456789]\d{9}$/.test(this.ruleForm.phone)) {
          this.$message.error("手机号格式不正确");
          return false;
        }
        api
          .addUserList({
            userName,
            userPass,
            photoUrl,
            phone,
            orgId,
            roleId,
            name,
            sex,
            entryDate,
            workState,
            date,
            cusSerSign,
            serviceType,
            cno,
            enterpriseCode,
            bindTelType,
            bindTel,
            workNumber,
            cnoPassword,
            post,
          })
          .then((res) => {
            console.log("添加用户：", res.data);
            if (res.data.code == 200) {
              //添加成功
              this.$message({
                message: "恭喜你，添加用户成功",
                type: "success",
              });
              //清空表单数据
              this.clearUserForm();
              //刷新页面数据
              //console.log('ye:',this.currentPage1);
              this.menulist(this.currentPage1);
            } else {
              //添加成功
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.dialogVisible = false;
              //清空表单数据
              this.clearUserForm();
            }
          });
      }
      // else {
      //   console.log("编辑用户");
      //   //对于入职日期的处理
      //   let chinaStanard = this.ruleForm.entryDate;
      //   let date = new Date(chinaStanard);
      //   let y = date.getFullYear();
      //   let m = date.getMonth() + 1;
      //   m = m < 10 ? "0" + m : m;
      //   let d = date.getDate();
      //   d = d < 10 ? "0" + d : d;
      //   let h = date.getHours();
      //   let minute = date.getMinutes();
      //   minute = minute < 10 ? "0" + minute : minute;
      //   let second = date.getSeconds();
      //   second = second < 10 ? "0" + second : second;
      //   if (this.ruleForm.entryDate == "") {
      //     this.ruleForm.entryDate = "";
      //   } else {
      //     this.ruleForm.entryDate =
      //       y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
      //   }

      //   //对于离职日期的处理
      //   let chinaStanard1 = this.ruleForm.quitDate;
      //   let dat1 = new Date(chinaStanard1);
      //   let y1 = dat1.getFullYear();
      //   let m1 = dat1.getMonth() + 1;
      //   m1 = m1 < 10 ? "0" + m1 : m1;
      //   let d1 = dat1.getDate();
      //   d1 = d1 < 10 ? "0" + d1 : d1;
      //   let h1 = dat1.getHours();
      //   let minute1 = dat1.getMinutes();
      //   minute1 = minute1 < 10 ? "0" + minute1 : minute1;
      //   let second1 = dat1.getSeconds();
      //   second1 = second1 < 10 ? "0" + second1 : second1;
      //   if (this.ruleForm.quitDate == "") {
      //     this.ruleForm.quitDate == "";
      //   } else {
      //     this.ruleForm.quitDate =
      //       y1 + "-" + m1 + "-" + d1 + " " + h1 + ":" + minute1 + ":" + second1;
      //   }
      //   this.ruleForm.roleId=this.roIdAA;
      //   if(this.ruleForm.workState=="在职"){
      //     this.ruleForm.workState="0"
      //   }else{
      //     this.ruleForm.workState="1"
      //   }
      //   // if(this.ruleForm.useState=="启用"){
      //   //   this.ruleForm.useState="0"
      //   // }else{
      //   //   this.ruleForm.useState="1"
      //   // }
      //   let {
      //     id,
      //     phone,
      //     orgId,
      //     roleId,
      //     name,
      //     sex,
      //     entryDate,
      //     quitDate,
      //     workState,
      //     useState,
      //     cusSerSign,
      //     serviceType,
      //     cno,
      //     enterpriseCode,
      //     bindTelType,
      //     bindTel,
      //     workNumber,
      //     cnoPassword,
      //     post,
      //   } = this.ruleForm;
      //   console.log(
      //     "=====222",
      //     id,
      //     phone,
      //     orgId,
      //     roleId,
      //     name,
      //     sex,
      //     entryDate,
      //     quitDate,
      //     workState,
      //     useState
      //   );
      //   api
      //     .changeUserList({
      //       id,
      //       phone,
      //       orgId,
      //       roleId,
      //       name,
      //       sex,
      //       entryDate,
      //       quitDate,
      //       workState,
      //       useState,
      //       cusSerSign,
      //       serviceType,
      //       cno,
      //       enterpriseCode,
      //       bindTelType,
      //       bindTel,
      //       workNumber,
      //       cnoPassword,
      //       post,
      //     })
      //     .then((res) => {
      //       console.log("修改用户:", res.data);
      //       if (res.data.code == 200) {
      //         //修改成功
      //         this.$message({
      //           message: "恭喜你，修改用户成功",
      //           type: "success",
      //         });
      //         //清空表单数据
      //         this.clearUserForm();
      //         //刷新页面数据
      //         //console.log('ye:',this.currentPage1);
      //         this.menulist(this.currentPage1);
      //       }
      //     });
      // }
    },
    //编辑用户
    changeUser(index, row) {
      this.dialogVisibleChange = true;
      console.log(index, row, "12221222");
      this.userId = row.id;
      this.orgIds = row.orgId;
      // this.ruleForm=row;
      if (row.cusSerSign == "1") {
        this.isCurs = true;
      } else {
        this.isCurs = false;
      }
      api.AllOrgRole({ orgId: this.orgIds }).then((res) => {
        console.log("组织机构下所有角色:", res.data);
        if (res.data.code === 200) {
          this.options4 = res.data.result;
        }
      });
      // console.log(this.orgIds,"角色展示")
      this.title = "编辑用户";
      this.isShow = true;
      this.istext = false;
      // this.ruleForm = { ...row };
      //console.log('修改之后：',this.ruleForm);
      // this.dialogVisible = true;
      api.searchUserList({ id: row.id }).then((res) => {
        console.log("查询用户详情：", res.data);
        this.ruleForm = res.data.result;
        this.roleValue = res.data.result.roleName;
        // this.ruleForm.orgId = res.data.result.orgName;
        // this.ruleForm.roleId=res.data.result.roleName;
      });
      this.roIdAA = row.roleId;
      // console.log("------", this.ruleForm.roleId);
    },
    //编辑的确定
    changeUserLs() {
      this.dialogVisibleChange = false;
      //对于时间的处理
      var d = new Date(this.ruleForm.entryDate);
      let youWant =
        d.getFullYear() +
        "-" +
        (d.getMonth() + 1) +
        "-" +
        d.getDate() +
        " " +
        d.getHours() +
        ":" +
        d.getMinutes() +
        ":" +
        d.getSeconds();
      this.ruleForm.entryDate = youWant;
      var c = new Date(this.ruleForm.quitDate);
      let youWant1 =
        c.getFullYear() +
        "-" +
        (c.getMonth() + 1) +
        "-" +
        c.getDate() +
        " " +
        c.getHours() +
        ":" +
        c.getMinutes() +
        ":" +
        c.getSeconds();
      this.ruleForm.quitDate = youWant1;
      if (this.flag == false) {
        if (this.ruleForm.useState == "启用") {
          this.ruleForm.useState = "0";
        } else {
          this.ruleForm.useState = "1";
        }
      } else {
        this.ruleForm.useState = this.US;
      }
      if (this.flagWS == false) {
        if (this.ruleForm.workState == "在职") {
          this.ruleForm.workState = "0";
        } else {
          this.ruleForm.workState = "1";
        }
      } else {
        this.ruleForm.workState = this.WS;
      }
      if (this.flagsex == false) {
        if (this.ruleForm.sex == "女") {
          this.ruleForm.sex = "0";
        } else {
          this.ruleForm.sex = "1";
        }
      } else {
        this.ruleForm.sex = this.XB;
      }
      // if (this.flagORG == false) {
      //   if (this.ruleForm.orgId == "康康科技有限公司") {
      //     this.ruleForm.orgId = "1";
      //   }
      // } else {
      //   this.ruleForm.orgId = this.ORG;
      // }
      if (this.flagcusSER == false) {
        if (this.ruleForm.cusSerSign == "否") {
          this.ruleForm.cusSerSign = "0";
        } else {
          this.ruleForm.cusSerSign = "1";
        }
      } else {
        this.ruleForm.cusSerSign = this.CS;
      }
      if (this.flagseverType == false) {
        if (this.ruleForm.serviceType == "客服中心") {
          this.ruleForm.serviceType = "1";
        } else if (this.ruleForm.serviceType == "外呼中心") {
          this.ruleForm.serviceType = "2";
        } else if (this.ruleForm.serviceType == "400中心") {
          this.ruleForm.serviceType = "3";
        }
      } else {
        this.ruleForm.serviceType = this.ST;
      }
      if (this.flagbindT == false) {
        if (this.ruleForm.bindTelType == "电话号码") {
          this.ruleForm.bindTelType = "1";
        } else if (this.ruleForm.bindTelType == "分机电话") {
          this.ruleForm.bindTelType = "2";
        } else if (this.ruleForm.bindTelType == "软电话") {
          this.ruleForm.bindTelType = "3";
        }
      } else {
        this.ruleForm.bindTelType = this.BT;
      }
      if (this.falgPOST == false) {
        if (this.ruleForm.post == "客服") {
          this.ruleForm.post = "1";
        } else if (this.ruleForm.post == "客服组长") {
          this.ruleForm.post = "2";
        } else if (this.ruleForm.post == "法律顾问") {
          this.ruleForm.post = "3";
        } else if (this.ruleForm.post == "政策解读") {
          this.ruleForm.post = "4";
        } else if (this.ruleForm.post == "关爱慰问") {
          this.ruleForm.post = "5";
        }
      } else {
        this.ruleForm.post = this.PT;
      }
      if (this.flagORG == false) {
        if (this.ruleForm.roleId == "康康管理员") {
          this.ruleForm.roleId = "1";
        } else if (this.ruleForm.roleId == "客服主管") {
          this.ruleForm.roleId = "3";
        } else if (this.ruleForm.roleId == "运营") {
          this.ruleForm.roleId = "4";
        } else if (this.ruleForm.roleId == "400客服") {
          this.ruleForm.roleId = "5";
        } else if (this.ruleForm.roleId == "callcenter") {
          this.ruleForm.roleId = "6";
        } else if (this.ruleForm.roleId == "外呼客服") {
          this.ruleForm.roleId = "7";
        }
      } else {
        this.ruleForm.roleId = this.ROLE;
      }
      let obj = {
        id: this.userId,
        phone: this.ruleForm.phone,
        orgId: this.ruleForm.orgId,
        roleId: this.ruleForm.roleId,
        name: this.ruleForm.name,
        sex: this.ruleForm.sex,
        entryDate: this.ruleForm.entryDate,
        quitDate: this.ruleForm.quitDate,
        workState: this.ruleForm.workState,
        useState: this.ruleForm.useState,
        cusSerSign: this.ruleForm.cusSerSign,
        serviceType: this.ruleForm.serviceType,
        cno: this.ruleForm.cno,
        enterpriseCode: this.ruleForm.enterpriseCode,
        bindTelType: this.ruleForm.bindTelType,
        bindTel: this.ruleForm.bindTel,
        workNumber: this.ruleForm.workNumber,
        cnoPassword: this.ruleForm.cnoPassword,
        post: this.ruleForm.post,
      };
      api.changeUserList(obj).then((res) => {
        console.log("修改用户:", res.data);
        if (res.data.code == 200) {
          //修改成功
          this.$message({
            message: "恭喜你，修改用户成功",
            type: "success",
          });
          //清空表单数据
          this.clearUserForm();
          //刷新页面数据
          //console.log('ye:',this.currentPage1);
          this.menulist(this.currentPage1);
        }
      });
    },
    //删除用户
    handleEdit(index, row) {
      console.log(index, row);
      this.dialogVisibleTwo = true;
      this.twoID = row.id;
    },
    deleteTwo() {
      this.dialogVisibleTwo = false;
      api.deleteUserList({ id: this.twoID }).then((res) => {
        //console.log("删除用户:", res.data);
        if (res.data.code == 200) {
          //删除成功
          this.$message({
            message: "恭喜你，删除用户成功",
            type: "success",
          });
          //清空表单数据
          this.clearUserForm();
          //刷新页面数据
          this.menulist(this.currentPage1);
        }
      });
    },
    //用户详情
    details(index, row) {
      // console.log(index,row);
      this.dialogVisible1 = true;
      api.searchUserList({ id: row.id }).then((res) => {
        // console.log("查询用户详情:", res.data);
        if (res.data.code == 200) {
          if (res.data.result.cusSerSign == "是") {
            this.isCurs = true;
          } else {
            this.isCurs = false;
          }
          let collectTime = res.data.result.createDate;
          this.timeData = new Date(parseInt(collectTime))
            .toLocaleString()
            .replace(/:\d{1,2}$/, " ");
          //console.log("时间：", this.timeData);
          let collTime = res.data.result.updateDate;
          this.timeDa = new Date(parseInt(collTime))
            .toLocaleString()
            .replace(/:\d{1,2}$/, " ");
          let coll = res.data.result.updateDate;
          this.timeD = new Date(parseInt(coll))
            .toLocaleString()
            .replace(/:\d{1,2}$/, " ");
          let coll1 = res.data.result.entryDate;
          this.timeD1 = new Date(parseInt(coll1))
            .toLocaleString()
            .replace(/:\d{1,2}$/, " ");
          if (res.data.result.quitDate !== null) {
            let coll2 = res.data.result.quitDate;
            this.timeD2 = new Date(parseInt(coll2))
              .toLocaleString()
              .replace(/:\d{1,2}$/, " ");
          } else {
            res.data.result.quitDate = "";
          }
          res.data.result.createDate = this.timeData;
          res.data.result.updateDate = this.timeDa;
          res.data.result.loginDate = this.timeD;
          res.data.result.entryDate = this.timeD1;
          res.data.result.quitDate = this.timeD2;
          this.ruleForm1 = res.data.result;
        } else {
          this.$message({
            message: "很遗憾，查询失败",
            type: "success",
          });
          this.dialogVisible = false;
          //清空表单数据
          this.clearUserForm();
        }
      });
    },
    //关闭详情按钮
    detailsX() {
      this.dialogVisible1 = false;
      //清空表单数据
      this.clearUserForm();
    },
    //时间戳转换
    formatter(row) {
      // console.log('行',row,'列',column);
      let date = row.createDate;
      return moment(date).format("YYYY-MM-DD");
      //return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
    formatter1(row) {
      // console.log('行',row,'列',column);
      let date = row.quitDate;
      //return moment(date).format("YYYY-MM-DD");
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    //清空列表
    clearUserForm() {
      this.dialogVisible = false; //隐藏弹框
      this.ruleForm = {};
      //单独清除下拉框
      this.value = "";
      this.value1 = "";
      this.value2 = "";
      this.value3 = "";
      this.value4 = "";
      this.value5 = "";
      this.value6 = "";
    },
    clearUserFormChange() {
      this.dialogVisibleChange = false;
      this.clearUserForm();
    },
    //是否在职和是否可登录的下拉框
    workIng() {
      api.smallLei({ dictCode: "isno" }).then((res) => {
        //console.log('大类下的小类：',res.data);
        if (res.data.code === 200) {
          this.optionsCus = res.data.result;
        }
      });
    },
    //性别的下拉框
    sexXia() {
      api.smallLei({ dictCode: "sex" }).then((res) => {
        //console.log('性别：',res.data);
        if (res.data.code === 200) {
          this.options2 = res.data.result;
        }
      });
    },
    //是否可登录的下拉框
    dengLu() {
      api.smallLei({ dictCode: "usestate" }).then((res) => {
        console.log("是否可登录：", res.data);
        if (res.data.code === 200) {
          this.options5 = res.data.result;
        }
      });
    },
    //在职状态的下拉框
    zaizhi() {
      api.smallLei({ dictCode: "workstate" }).then((res) => {
        console.log("在职状态：", res.data);
        if (res.data.code === 200) {
          this.options1 = res.data.result;
        }
      });
    },
    //客服类型的下拉框
    serviceTs() {
      api.smallLei({ dictCode: "servicetype" }).then((res) => {
        console.log("客服类型：", res.data);
        if (res.data.code === 200) {
          this.optionsserviceType = res.data.result;
          this.ruleForm.optionsserviceType = res.data.result;
        }
      });
    },
    //客服岗位的下拉
    cnoWei() {
      api.smallLei({ dictCode: "servicepost" }).then((res) => {
        console.log("客服岗位：", res.data);
        if (res.data.code === 200) {
          this.optionspost = res.data.result;
          this.ruleForm.optionspost = res.data.result;
        }
      });
    },
    //绑定电话类型
    bindTelTypes() {
      api.smallLei({ dictCode: "bindteltype" }).then((res) => {
        console.log("绑定电话类型：", res.data);
        if (res.data.code === 200) {
          this.optionsbindTelType = res.data.result;
          this.ruleForm.optionsbindTelType = res.data.result;
        }
      });
    },
    //绑定电话类型
    getbindTelType(dd) {
      console.log("绑定电话类型：", dd);
      this.flagbindT = true;
      this.BT = dd;
      // if (dd === "") {
      //   this.ruleForm.bindTelType = "";
      // } else {
      //   this.ruleForm.bindTelType = dd;
      // }
    },
    //客服岗位
    getpost(cc) {
      console.log("客服岗位：", cc);
      this.falgPOST = true;
      this.PT = cc;
      // if (cc === "") {
      //   this.ruleForm.post = "";
      // } else {
      //   this.ruleForm.post = cc;
      // }
    },
    //客服类型
    getserviceType(bb) {
      console.log("客服类型：", bb);
      this.flagseverType = true;
      this.ST = bb;
      // if (bb === "") {
      //   this.ruleForm.serviceType = "";
      // } else {
      //   this.ruleForm.serviceType = bb;
      // }
    },
    //是否为客服
    getUserValueCus(aa) {
      console.log("客服：", aa);
      this.flagcusSER = true;
      this.CS = aa;
      // if (aa === "") {
      //   this.ruleForm.cusSerSign = "";
      // } else {
      //   this.ruleForm.cusSerSign = aa;
      // }
      if (aa == "1") {
        this.isCurs = true;
      } else {
        this.isCurs = false;
        this.ruleForm.serviceType = "";
        this.ruleForm.cno = "";
        this.ruleForm.enterpriseCode = "";
        this.ruleForm.bindTelType = "";
        this.ruleForm.bindTel = "";
        this.ruleForm.workNumber = "";
        this.ruleForm.cnoPassword = "";
        this.ruleForm.post = "";
      }
    },
    //是否在职
    getUserValue(value) {
      console.log("value", value);
      this.flagWS = true;
      this.WS = value;
      // if (value === "") {
      //   this.ruleForm.workState = "";
      // } else {
      //   this.ruleForm.workState = value;
      // }
    },
    //是否可登录
    getUserValue1(value) {
      console.log("是否可登录：", value);
      this.flag = true;
      this.US = value;
      // if (value === "") {
      //   this.ruleForm.useState = "";
      // } else {
      //   this.ruleForm.useState = value;
      // }
    },
    //角色ID
    getUserRoleValue(value) {
      console.log("----:", value);
      this.flagROLE = true;
      this.ROLE = value;
      this.ruleForm.roleId = value;
      // if (value === "") {
      //   this.ruleForm.roleId = "";
      //   // api.AllOrgRole({ orgId: this.ruleForm.orgId }).then((res) => {
      //   //   console.log("组织机构下所有角色：", res.data);
      //   //   if (res.data.code === 200) {
      //   //     this.options4 = res.data.result;
      //   //     this.ruleForm.options4 = res.data.result;
      //   //   }
      //   // });
      // } else {
      //   this.ruleForm.roleId = value;
      // }
    },
    //性别
    getSexValue(value) {
      console.log("性别的选中：", value);
      this.flagsex = true;
      this.XB = value;
      //console.log('别：',value);
      // if (value === "") {
      //   this.ruleForm.sex = "";
      // } else {
      //   this.ruleForm.sex = value;
      // }
    },
    //查询所有机构的下拉框
    searchAllJigou() {
      api.searchAllOrg().then((res) => {
        //console.log('所有机构：',res.data);
        if (res.data.code === 200) {
          this.options3 = res.data.result;
        }
      });
    },
    //机构
    getjigouValue(value) {
      // console.log('jigou:',value);
      this.flagORG = true;
      this.ORG = value;
      if (value == "") {
        this.ruleForm.orgId = "";
      } else {
        this.ruleForm.orgId = value;
        api.AllOrgRole({ orgId: this.ruleForm.orgId }).then((res) => {
          console.log("组织机构下所有角色：", res.data);
          if (res.data.code === 200) {
            this.options4 = res.data.result;
          }
        });
      }
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage1 = val;
      this.menulist(val);
    },
  },
};
</script>

<style lang="less" scoped>
.userguanli {
  // 搜索
  .sousuo {
    // background: red;
    display: flex;
    align-items: center;
    margin-left: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    .div1 {
      margin-right: 20px;
      .inputs {
        width: 231px;
        height: 33px;
        border: 1px solid #e5e5e5;
        margin-left: 5px;
      }
      .inputs:focus {
        outline: 1px solid #e5e5e5; //边框不用border，用outline
      }
      span {
        font-size: 14px;
        color: #909399;
        font-weight: 700;
      }
      .select {
        margin-left: 20px;
      }
    }
    .imgs {
      width: 49px;
    }
  }
  //   table
  .tables {
    position: relative;
    margin-left: 25px;
    margin-right: 40px;

    .tingyong {
      color: rgb(97, 97, 245);
    }
    .buts {
      width: 36px;
      height: 27px;
      margin: 0 10px;
    }
    .el-table {
      // max-height: 500px;
      // overflow: hidden;
      tr {
        th {
          font-size: 14px;
          border: none;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #909399;
        }
        td {
          border: none;
          text-align: center;
          font-size: 14px;
          color: #606266;
        }
      }
      .details {
        height: 27px;
        border-radius: 15px;
      }
    }
    .el-table::before {
      height: 0;
    }
    // .details {
    //   height: 30px;
    //   position: absolute;
    //   top: 12px;
    //   right: 1px;
    //   border-radius: 15px;
    // }
  }
  /deep/ .el-table .el-table__cell {
    padding: 12px 0;
    min-width: 0;
    box-sizing: border-box;
    text-overflow: ellipsis;
    vertical-align: middle;
    position: relative;
    text-align: center;
  }
  /deep/ .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: none;
  }
  /deep/ th.el-table__cell.is-leaf {
    border-bottom: none;
  }
  .totals {
    display: flex;
    justify-content: space-between;
    .left {
      p {
        font-size: 16px;
        display: inline-block;
        margin-right: 80px;

        margin-left: 50px;

        margin-top: 20px;
        color: #606266;
      }
      .spanto {
        color: #92aca7;
        margin-left: 17px;
        margin-right: 12px;
      }
    }
    .total {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 20px;
      margin-right: 50px;
      // 按钮
      .buttons {
        display: flex;
        .el-button {
          width: 130px;
          height: 40px;
          line-height: 40px;
          border-radius: 25px;
          margin-top: 20px;
          margin-right: 20px;
          background: #d78673;
          color: #ffffff;
          font-size: 16px;
          padding: 0px;
        }
      }
      // page
      .pages {
        display: flex;
        align-items: center;
        .el-pagination {
          padding: 0;
          img {
            display: inline-block;
          }
          .lefts {
            margin-right: 25px;
          }
          .rights {
            float: right;
            margin-left: 25px;
          }
        }
      }
    }
  }
  // .el-input__inner {
  //   width: 50%;
  // }
  .lizhi {
    margin-left: -10px;
  }
  .account .el-input__inner {
    border: 1px solid #fff;
  }
  .el-form-item__label {
    margin-left: 60px;
  }
  .workState {
    margin: 70px 120px;
    span {
      margin-right: 15px;
    }
  }
  .sexState {
    position: absolute;
    right: 173px;
    top: 245px;
    span {
      margin-right: 15px;
    }
  }
  .jigouState {
    position: absolute;
    right: 173px;
    top: 180px;
    span {
      margin-right: 15px;
    }
  }
  .jueseState {
    position: absolute;
    left: 133px;
    top: 245px;
    span {
      margin-right: 15px;
    }
  }
  .dengState {
    position: absolute;
    left: 105px;
    top: 180px;
    span {
      margin-right: 15px;
    }
  }
  .workState1 {
    margin: 130px 120px;
    span {
      margin-right: 15px;
    }
  }
  // // 弹框
  // .el-dialog {
  //   border-radius: 18px;
  // }
  // .el-dialog__header {
  //   background: #eaeaea;
  //   border-radius: 18px 18px 0 0;
  // }
  // .el-dialog__close {
  //   color: transparent;
  //   background-image: url("../../assets/images/close.png");
  //   background-repeat: no-repeat;
  //   background-size: 14px;
  // }
  // .el-dialog__body {
  //   background: #eaeaea;
  //   border-radius: 0 0 18px 18px;
  //   padding: 10px 0px 0 0px;
  // }
  .accountdd {
    .el-dialog__header {
      background: #ffffff;
      border-radius: 18px 18px 0 0;
    }
    .el-dialog__body {
      background: #ffffff;
      border-radius: 0 0 18px 18px;
      padding: 10px 0px 0 0px;
    }
  }
  /deep/.el-input__inner {
    border-radius: 0;
    height: 33px !important;
    line-height: 33px !important;
  }
  /deep/.el-input__icon {
    height: 100%;
    width: 25px;
    text-align: center;
    transition: all 0.3s;
    line-height: 33px !important;
  }
  /deep/.el-form-item__label {
    color: #909399;
    text-align: center;
  }
  /deep/.el-dialog__body {
    padding: 0;
    margin-left: 2%;
  }
  /deep/.el-table td,
  .el-table th.is-leaf {
    border-bottom: none !important;
  }
  /deep/.el-table .cell {
    text-align: center !important;
  }
  /deep/.el-table thead tr th.is-leaf {
    border: none;
    border-right: none;
  }
}
</style>